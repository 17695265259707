




















































































































































































import { Bar } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js'

import { getElasticSearchClient } from '@/services/elasticSearch'
import DatePicker from '@/components/reports/DatePicker.vue'
import GroupByIntervalSelect from '@/components/reports/GroupByIntervalSelect.vue'
import ManageFilters from '@/components/reports/ManageFilters.vue'
import { CurrencyEnum } from '../../../../shared/types/types'
import { formatPrice } from '../../lib/utils'
import DatePickerMixin from '../../mixins/DatePicker.mixin'
import { AndFilterGroup, Filter, IntervalEnum } from '../../lib/reports/types'
import {
  elasticSearchFilterQuery,
  filterPriceLabel,
  filterStartCase,
  filterValueLabel,
  totalSalesBackgroundOpacity,
} from '../../lib/reports/functions'
import CurrencySelect from '../../components/shared/inputs/CurrencySelect.vue'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

interface Aggr {
  key: string
  quantity: { value: number }
  sumRevenue: { value: number }
  sumDiscounts: { value: number }
  sumReturns: { value: number }
  sumNet: { value: number }
  productTitle: any
}

export default DatePickerMixin.extend({
  name: 'ProductReport',
  components: {
    Bar,
    DatePicker,
    GroupByIntervalSelect,
    CurrencySelect,
  },
  filters: {
    filterPriceLabel,
    filterStartCase,
    filterValueLabel,
  },
  data() {
    return {
      currency: CurrencyEnum.Usd as CurrencyEnum,
      switch: false,
      timeInterval: IntervalEnum.Day,
      dataFilters: [
        {
          orGroups: [
            { filter: 'ordersPaymentStatus', operator: 'is', values: ['paid'] },
            {
              filter: 'ordersPaymentStatus',
              operator: 'is',
              values: ['partially_refunded'],
            },
            {
              filter: 'ordersPaymentStatus',
              operator: 'is',
              values: ['refunded'],
            },
          ],
        },
      ] as AndFilterGroup[],
      data: null as Record<string, any> | null,
      visibleColumns: [
        'key',
        'count',
        'revenue',
        'discounts',
        'returns',
        'net',
        'tax',
        'shipping',
      ] as string[],
    }
  },
  computed: {
    query(): Record<string, any> {
      const fieldPrefix = this.currency === CurrencyEnum.Usd ? 'usdPrices.' : ''
      return {
        aggs: {
          lineItems: {
            nested: {
              path: 'lineItems',
            },
            aggs: {
              byProduct: {
                terms: {
                  field: 'lineItems.productId',
                },
                aggs: {
                  quantity: {
                    sum: {
                      field: 'lineItems.quantity',
                    },
                  },
                  sumRevenue: {
                    sum: {
                      field: `lineItems.${fieldPrefix}totalPrice.amount`,
                    },
                  },
                  sumDiscounts: {
                    sum: {
                      field: `lineItems.${fieldPrefix}discountPrice.amount`,
                    },
                  },
                  sumNet: {
                    sum: {
                      field: `lineItems.${fieldPrefix}netPayment.amount`,
                    },
                  },
                  productTitle: {
                    top_metrics: {
                      metrics: {
                        field: 'lineItems.productTitle.keyword',
                      },
                      size: 1,
                      sort: {
                        '@timestamp': 'asc',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        size: 0,
        query: elasticSearchFilterQuery(this.dataFilters, this.datePicker),
      }
    },
    rows(): Aggr[] {
      const buckets = this.data?.aggregations.lineItems.byProduct.buckets ?? []
      return buckets.map((bucket: any) => ({
        key: bucket.key,
        title:
          bucket.productTitle.top[0].metrics['lineItems.productTitle.keyword'],
        quantity: bucket.quantity,
        sumRevenue: bucket.sumRevenue,
        sumDiscounts: bucket.sumDiscounts,
        sumNet: bucket.sumNet,
        productTitle: bucket.productTitle,
      }))
    },
    sum(): Aggr {
      return this.rows.reduce(
        (acc: Aggr, b: Aggr) => {
          acc.sumRevenue.value = acc.sumRevenue.value + b.sumRevenue.value
          acc.sumDiscounts.value = acc.sumDiscounts.value + b.sumDiscounts.value
          acc.sumNet.value = acc.sumNet.value + b.sumNet.value
          acc.quantity.value = acc.quantity.value + b.quantity.value
          return acc
        },
        {
          quantity: { value: 0 },
          sumDiscounts: { value: 0 },
          sumNet: { value: 0 },
          sumRevenue: { value: 0 },
        } as Aggr
      )
    },
    chartData(): Record<string, any> {
      return {
        labels: this.rows.map(
          (row) =>
            row.productTitle.top[0].metrics['lineItems.productTitle.keyword']
        ),
        datasets: [
          {
            label: '$ Net',
            backgroundColor: '#01A19F',
            data: this.rows.map((row) => row.sumRevenue.value / 100),
          },
        ],
      }
    },
    chartOptions() {
      return {
        responsive: true,
        minBarLength: 5,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              stepSize: 100,
              callback: (value: any) =>
                formatPrice({
                  amount: value * 100,
                  currency: this.currency,
                }),
            },
          },
        },
      }
    },
    totalSalesBackgroundOpacity(): Record<number, number> {
      return totalSalesBackgroundOpacity<Aggr>(this.rows, this.netSales)
    },
  },
  watch: {
    datePicker: {
      handler(): void {
        this.fetchData()
      },
      deep: true,
    },
    timeInterval(): void {
      this.fetchData()
    },
    dataFilters: {
      handler(): void {
        this.fetchData()
      },
      deep: true,
    },
    currency: {
      immediate: true,
      handler(currency): void {
        if (currency === CurrencyEnum.Usd) {
          let hasTotalPriceCurrency = false
          for (const andGroup of this.dataFilters) {
            for (const orGroup of andGroup.orGroups) {
              if (orGroup.filter === 'totalPrice.currency') {
                hasTotalPriceCurrency = true
              }
            }
          }
          if (hasTotalPriceCurrency) {
            this.dataFilters = this.dataFilters.filter((andGroup) => {
              return !andGroup.orGroups.some(
                (orGroup) => orGroup.filter === 'totalPrice.currency'
              )
            })
          }
        } else {
          let hasTotalPriceCurrency = false
          for (const andGroup of this.dataFilters) {
            for (const orGroup of andGroup.orGroups) {
              if (orGroup.filter === 'totalPrice.currency') {
                hasTotalPriceCurrency = true
                orGroup.values = [currency]
              }
            }
          }
          if (!hasTotalPriceCurrency) {
            this.dataFilters.push({
              orGroups: [
                {
                  filter: 'totalPrice.currency',
                  operator: 'is',
                  values: [currency],
                },
              ],
            })
          }
        }
      },
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    formatPrice(price: number): string {
      return formatPrice({ amount: price, currency: CurrencyEnum.Usd })
    },
    async fetchData(): Promise<void> {
      const elasticSearch = getElasticSearchClient()
      const { data } = await elasticSearch.post('/orders/_search', this.query)
      this.data = data
    },
    onRangeChanged(range: { startDate: Date; endDate: Date }): void {
      this.datePicker.startDate = range.startDate
      this.datePicker.endDate = range.endDate
    },
    openManageFilters(): void {
      this.$buefy.modal.open({
        parent: this,
        customClass: 'manage-filters',
        scroll: 'keep',
        component: ManageFilters,
        hasModalCard: true,
        props: {
          initFilters: this.dataFilters,
        },
        trapFocus: true,
        events: {
          value: (value: AndFilterGroup[]): void => {
            this.dataFilters = JSON.parse(
              JSON.stringify(value)
            ) as AndFilterGroup[]
          },
        },
      })
    },
    removeAndGroup(group: AndFilterGroup) {
      this.dataFilters.splice(this.dataFilters.indexOf(group), 1)
    },
    removeOrGroup(andGroup: AndFilterGroup, orGroup: Filter) {
      if (andGroup.orGroups.length === 1) {
        // If there are more and groups, but there is only one orGroup inside then remove the andGroup
        this.removeAndGroup(andGroup)
      } else {
        andGroup.orGroups.splice(andGroup.orGroups.indexOf(orGroup), 1)
      }
    },
    netSales(row: Aggr) {
      return row.sumRevenue.value - row.sumDiscounts.value
    },
  },
})
